import classNames from 'classnames';

const ChevronIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { className = '', ...attributes } = props;
  const classes = classNames('vector-icon', className);

  return (
    <svg
      width="4"
      height="8"
      viewBox="0 0 4 8"
      fill="none"
      className={classes}
      {...attributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.14645 7.35331C0.34171 7.54857 0.658295 7.54857 0.853555 7.35331L3.8535 4.35331C4.0488 4.15806 4.0488 3.84146 3.8535 3.64621L0.853555 0.646205C0.658295 0.450955 0.34171 0.450955 0.14645 0.646205C-0.0488155 0.841455 -0.0488158 1.15806 0.146444 1.35331L2.79285 3.99976L0.146444 6.64621C-0.0488158 6.84147 -0.0488155 7.15805 0.14645 7.35331Z"
        fill="white"
      />
    </svg>
  );
};

export default ChevronIcon;
