import AmdLogo from './GpuBrandsLogo/AmdLogo';
import ArrowCircleIcon from './decorsAndNavigation/ArrowCircleIcon';
import ArrowDownloadIcon from './decorsAndNavigation/ArrowDownloadIcon';
import ArrowRightRedirect from './decorsAndNavigation/ArrowRightRedirect';
import ChevronIcon from './decorsAndNavigation/ChevronIcon';
import BackgroundArrowFirst from './backgroundElements/BackgroundArrowFirst';
import BackgroundArrowSecond from './backgroundElements/BackgroundArrowSecond';
import BoostIcon from './features/BoostIcon';
import BtcMountainIcon from './miners/BtcMountainIcon';
import BtcHandIcon from './features/BtcHandIcon';
import BtcProgramsIcon from './features/BtcProgramsIcon';
import BtcHeadphonesIcon from './miners/BtcHeadphonesIcon';
import MinerIcon from './miners/MinerIcon';
import EthLogo from './miners/EthLogo';
import ClockIcon from './decorsAndNavigation/ClockIcon';
import CloudIcon from './features/CloudIcon';
import DiscordLogo from './socials/DiscordLogo';
import DockerLogo from './TemplateBrandsLogo/DockerLogo';
import EnvelopeIcon from './decorsAndNavigation/EnvelopeIcon';
import FacebookLogo from './socials/FacebookLogo';
import FlagChina from './languageFlags/FlagChinaIcon';
import FlagRussia from './languageFlags/FlagRussiaIcon';
import FlagSpain from './languageFlags/FlagSpainIcon';
import FlagThai from './languageFlags/FlagThaiIcon';
import FlagUsa from './languageFlags/FlagUsaIcon';
import FlashDriveIcon from './hardwareDevices/FlashDriveIcon';
import HddDriveIcon from './hardwareDevices/HddDriveIcon';
import HddIcon from './hardwareDevices/HddIcon';
import InternetPortIcon from './hardwareDevices/InternetPortIcon';
import JupyterLogo from './TemplateBrandsLogo/JupyterLogo';
import ManageIcon from './features/ManageIcon';
import ManualIcon from './decorsAndNavigation/ManualIcon';
import NvidiaLogo from './GpuBrandsLogo/NvidiaLogo';
import PcIcon from './hardwareDevices/PcIcon';
import QuestionMarksIcon from './decorsAndNavigation/QuestionMarksIcon';
import SmBrandName from './smLogo/SmBrandName';
import SmLogo from './smLogo/SmLogo';
import SpBrandName from './spLogo/SpBrandName';
import SpLogo from './spLogo/SpLogo';
import SsdIcon from './hardwareDevices/SsdIcon';
import SupportIcon from './features/SupportIcon';
import TensorflowLogo from './TemplateBrandsLogo/TensorflowLogo';
import ThumbsDownIcon from './decorsAndNavigation/ThumbsDownIcon';
import ThumbsUpIcon from './decorsAndNavigation/ThumbsUpIcon';
import TwitterLogo from './socials/TwitterLogo';
import UpdateIcon from './features/UpdateIcon';
import WiFiIcon from './hardwareDevices/WiFiIcon';
import CompareIcon from './compare/CompareIcon';
import PricingIcon from './compare/PricingIcon';
import PytorchLogo from './TemplateBrandsLogo/PytorchLogo';

export {
  AmdLogo,
  ArrowCircleIcon,
  ArrowDownloadIcon,
  ArrowRightRedirect,
  ChevronIcon,
  BackgroundArrowFirst,
  BackgroundArrowSecond,
  BoostIcon,
  BtcMountainIcon,
  BtcHandIcon,
  BtcProgramsIcon,
  BtcHeadphonesIcon,
  MinerIcon,
  ClockIcon,
  CloudIcon,
  CompareIcon,
  DiscordLogo,
  DockerLogo,
  EnvelopeIcon,
  EthLogo,
  FacebookLogo,
  FlagChina,
  FlagRussia,
  FlagSpain,
  FlagThai,
  FlagUsa,
  FlashDriveIcon,
  HddDriveIcon,
  HddIcon,
  InternetPortIcon,
  JupyterLogo,
  ManageIcon,
  ManualIcon,
  NvidiaLogo,
  PcIcon,
  PricingIcon,
  QuestionMarksIcon,
  SmBrandName,
  SmLogo,
  SpBrandName,
  SpLogo,
  SsdIcon,
  SupportIcon,
  TensorflowLogo,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TwitterLogo,
  UpdateIcon,
  WiFiIcon,
  PytorchLogo,
};
